import React from 'react';
import { withRouter } from 'react-router-dom';

import AnniversaryPill from '../../assets/anniversary-min.png';
import ItemClaimCard from '../../components/ItemClaimCard/ItemClaimCard';

import './Claim.css';

function ItemClaim() {

  return (
    <div className="ItemClaim">
     
      <div className="ItemClaim_MainSection">
      <img alt="byoplogo" className="Pill_Logo" src={AnniversaryPill} />
        <ItemClaimCard color="yellow" border="yellow" />
      </div>

      <div className="ItemClaim_Header">
        <a style={{display: 'flex'}} href="https://byopills.com" target="_blank" rel="noopener noreferrer" aria-current="page"><img src="https://uploads-ssl.webflow.com/619b48f76e0527c64a2c3dc1/619b55181893d62a38c24b6b_Pill%20Icon.svg" loading="lazy" width="50" alt="" /><img className="byoimg" src="https://uploads-ssl.webflow.com/619b48f76e0527c64a2c3dc1/61a0c8675f6b9e55d18c9053_One%20word.svg" loading="lazy" width="120" alt=""></img></a>
        
      </div>

      <a href="https://etherscan.io/address/0x347114ba00e33b5641c4f3bb0223e89852fbc833#code" target="_blank" rel="noopener noreferrer" className="ItemClaim_Footer">BYOX Smart Contract</a>
    </div>
  );
}

export default withRouter(ItemClaim);
