import React, { useContext, useState, useEffect } from 'react';

import { SkyLightStateless } from 'react-skylight';
import Loader from 'react-loader-spinner';
import { Web3Context } from '../../data/Web3Context/Web3Context';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './ItemClaimCard.css';

function ItemClaimCard() {
  const { web3, account, connectMetamask, itemsContract } =
    useContext(Web3Context);
  const [transactionHash, setTransactionHash] = useState(null);
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [eligibleCount, setEligibleCount] = useState(-1);
  const [claimingOpen, setClaimingOpen] = useState (true);
  const [modalOpen, setModalOpen] = useState(false);
  const [canMint, setCanMint] = useState(true);

  useEffect(() => {
    if (!itemsContract) {
      return;
    }
    
    getSnapshotCount();
    getClosed();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, itemsContract]);

  const getSnapshotCount = () => {
    console.log (account)
    fetch(`https://api.byopills.com/byoxitems/snapshot/${account}`)
      .then((data) => data.json())
      .then((parsed) => {
        setEligibleCount(parsed.count);
        console.log (parsed.count)
      });
  };

  const getClosed = async () => {
    try {
      const { isCollectingOpen } = itemsContract.methods;
      const isOpen = await isCollectingOpen(0).call();
      setClaimingOpen(isOpen);
    } catch (e) {
      console.log(e);
    }
  }

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const mint = async () => {
    if (!canMint) {
      return;
    }

    setLoading(true);
    setCanMint(false);

    const fetchRes = await fetch(
      `https://api.byopills.com/byoxitems/merkle/proof/${account}`,
    );
    const data = await fetchRes.json();

    if (!data) {
      alert('An unknown error has occured. Please try again in a few.');
      setLoading(false);
      setCanMint(true);
      return;
    }

    const { idx, maxCount, proof } = data;

    if (proof === 'Not Found' || isNaN(maxCount)) {
      alert('This address is not on the whitelist.');
      setLoading(false);
      setCanMint(true);
      return;
    }

    // Reset
    setTransactionHash(null);
    setTransactionConfirmed(false);

    const { whitelistCollect } = itemsContract.methods;

    whitelistCollect(0, 1, idx, maxCount, proof).estimateGas(
      {
        from: account,
      },
      async (err, gas) => {
        if (err) {
          if (err.message.indexOf('Sale must') > -1) {
            alert('Sale is not active yet !');
          } else if (err.message.indexOf('Tx max') > -1) {
            alert('75 max per transaction !');
          }
           else if (err.message.indexOf('Collecting not') > -1) {
            alert(
              'Item collection is not open yet ! Please wait until it is open.',
            );
          } else if (err.message.indexOf('Invalid merkle') > -1) {
            alert(
              'An error has occured, please make sure your address is whitelisted.',
            );
          } else if (err.message.indexOf('Minting more') > -1) {
            alert(
              'You have minted your anniversary pill already.',
            );
          }
          setLoading(false);
          setCanMint(true);
          return;
        }

        whitelistCollect(0, 1, idx, maxCount, proof).send(
          {
            from: account,
            gas: gas,
          },
          async function (error, txHash) {
            if (error) {
              setLoading(false);
              setCanMint(true);
              return;
            }

            setTransactionHash(txHash);
            setModalOpen(true);
            setLoading(false);
            setCanMint(true);

            let transactionReceipt = null;
            while (transactionReceipt == null) {
              transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
              await sleep(1000);
            }

            setTransactionConfirmed(true);
            setTransactionHash(transactionReceipt.transactionHash);
          },
        );
      },
    );
  };

  return (
    <div className="ItemClaimCard">
      <SkyLightStateless
        isVisible={modalOpen}
        onCloseClicked={() => {
          setModalOpen(false);
        }}
      >
        <div className="Modal_Body">
          {!transactionConfirmed ? (
            <div className="Modal_Title">
              <Loader type="Puff" color="white" height={50} width={50} />
              <p className="Modal_Status">
                Transaction Processing ... <br />
                <span>
                  You can either wait or close this and check your wallet or
                  etherscan for confirmation
                </span>
              </p>
            </div>
          ) : (
            <div className="Modal_Title">
              <p>Transaction Confirmed !</p>
            </div>
          )}

          <p>SUMMARY</p>
          <div className="separator"></div>
          <div className="Modal_Entries">
            <div className="Modal_Entry">
              <p>TRANSACTION HASH</p>
              <p>
                {transactionHash}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://etherscan.io/tx/${transactionHash}`}
                >
                  MORE INFO...
                </a>
              </p>
            </div>
            <div className="Modal_Entry">
              <p>FROM</p>
              <p>{account}</p>
            </div>
          </div>
          <div className="separator"></div>

          <div className="Modal_Actions">
            <button
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Mint more
            </button>
          </div>
          {transactionConfirmed && (
            <div className="Modal_Confirmation">
              Your transaction has now been confirmed ! The Anniversary Pill should now
              be minted , and visible on{' '}
              <a href="https://opensea.io/collection/byox">Opensea.io 🙌 </a>
            </div>
          )}
        </div>
      </SkyLightStateless>

      <div>
        <p className="Info_Title" style={{ color: "black" }}>
          Mint <span>BYO 1 Year Anniversary Pill</span> for <span>FREE + GAS</span>
        </p>
        <p className="Info_Title" style={{ color: "black" }}>
          NOTE: 1 PER WHITELISTED WALLET
        </p>
      </div>

      {account ? (
        !isLoading ? (

          claimingOpen ?
          <button
            onClick={() => mint()}
            className="ItemClaimCard_ActionButton"
          >
            MINT PILL
          </button>
          :
          <button
            className="ItemClaimCard_ActionButton"
          >
            CLAIMING CLOSED
          </button>
        ) : (
          <button className="ItemClaimCard_ActionButton">LOADING ...</button>
        )
      ) : (
        <button
          onClick={() => connectMetamask()}
          className="ItemClaimCard_ActionButton"
        >
          CONNECT WALLET
        </button>
      )}

      {account ? (
        eligibleCount > 0 ? 
        <p style={{ color: "white" }}>Your wallet is eligible !</p> : null
      ): null}

    </div>
  );
}

export default ItemClaimCard;
